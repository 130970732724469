<template>
    <div class="template-page article">
        <basic-page :pagedata="this.pageData" :bannerimg="this.articleImage">
            <div v-if="articleData.attributes && articleData.attributes.body" v-html="articleData.attributes.body[0].processed"></div>

            <br>
            <br>
            <br>

            <h3 v-if="this.articleData.field_article_attachment && this.articleData.field_article_attachment.length">Attachment/s</h3>
            <div v-if="articleData.field_article_attachment && articleData.field_article_attachment.length">
                <div v-for="(attachment, index) in articleData.field_article_attachment" :key="index">
                    <a v-if="articleData.relationships.field_article_attachment.data[index].meta.description"
                        class="cta attachment" :href="fileSrc(attachment)" target="_blank" download>
                        {{ articleData.relationships.field_article_attachment.data[index].meta.description }}</a>
                    <a v-else class="cta attachment" :href="fileSrc(attachment)" target="_blank" download>
                        {{ attachment.attributes.filename }}
                    </a>
                </div>
            </div>

            <br>

            <div class="back-button">
                <router-link to="/blog" class="cta-link">
                    <span>Back to Blog</span>
                </router-link>
            </div>
        </basic-page>
    </div>
</template>

<script>
import BasicPage from '../BasicPage.vue'
import { fetchNodes, fetchSingleNode } from '../../libs/drupalClient';

export default {
    name: 'blog-article',
    components: {
        BasicPage
    },
    data: () => {
        return {
            pageData: {
            },
            articleData: {},
            introimgbannerID: '2262573e-274d-44b3-9172-34e2db37c225',
            introimg: ''
        }
    },
    computed: {
        articleNID() {
            return this.$route.params.nid
        },
        articleImage() {
            return process.env.VUE_APP_ENDPOINT + 
                (this.articleData.field_image ? 
                    this.articleData.field_image.attributes.uri.url : 
                    this.introimg
                )
        }
    },
    methods: {
        fileSrc(att) {
            return process.env.VUE_APP_ENDPOINT + att.attributes.uri.url
        },
        fetchArticles() {
            if(this.articleNID) {
                fetchSingleNode('page', this.introimgbannerID, { 
                    include: ['field_banner_top'] }
                ).then(res => {
                    if(res[0].field_banner_top) this.introimg = res[0].field_banner_top.attributes.uri.url
                })

                fetchNodes('article', {
                    filters: [{
                        key: 'drupal_internal__nid',
                        value: this.articleNID
                    }],
                    include: ['field_image', 'field_ref_author', 'field_article_attachment']
                }).then(res => {
                    //console.log(res[0])
                    this.articleData = res[0]
                    this.pageData = {
                        title: this.articleData.attributes.title,
                        body: [
                            {
                                summary: this.articleData.attributes.body[0].summary
                            }
                        ]
                    }
                })
            }   
        },
    },
    mounted() {
        this.fetchArticles()
    }
}
</script>

<style lang="scss" scoped>

</style>